import React from 'react';
import { Center, Container } from '@fiverr-private/layout_components';
import { StandaloneIdentificationForm } from '@fiverr-private/identification_marketplace';
import { SigningMode } from '../../../types';
import * as classes from './NewIdentification.ve.css';
import '../shared/styles/Global.ve.css';

interface NewIdentificationProps {
  mode: SigningMode;
}

export const NewIdentification = ({ mode }: NewIdentificationProps) => (
  <Center paddingY={{ default: '0', md: '10' }}>
    <Container
      className={classes.formBoxShadow}
      height="645px"
      width={{ default: '100%', md: '900px' }}
      maxWidth="100%"
      backgroundColor="white"
      borderRadius="xl"
      overflow="hidden"
    >
      <StandaloneIdentificationForm mode={mode} />
    </Container>
  </Center>
);
